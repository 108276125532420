import classNames from 'classnames';
import { get, isEqual } from 'lodash';
import moment from 'moment-timezone';
import { bool, func, object, string } from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import {
  Button,
  FieldSelect,
  FieldTextInput,
  Form,
  InsurancePanelNew,
  NamedLink,
  PrimaryButton,
} from '../../components';
import config from '../../config';
import {
  currentUserCanRequestToBooking,
  currentUserIdentityStatus,
  isChargeDeposit,
  listingIsInstantBooking,
} from '../../util/data';
import { sameDay, timestampToDate } from '../../util/dates';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { sendCheckoutSlackNotification } from '../../util/slackNotify';
import { bookingTypes, propTypes } from '../../util/types';
import css from './BookingTimeForm.css';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldEndDateAndTimeInput from './FieldEndDateAndTimeInput';
import FieldStartDateAndTimeInput from './FieldStartDateAndTimeInput';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import { EVENT_SEARCH_PRESSED_BOOK_GUEST_ERROR } from '../../util/gtm/gtmConstants';
import AlertBox from '../../components/AlertBox/AlertBox';
import BookingPeriod from './BookingPeriod';


export class BookingTimeFormComponent extends PureComponent {
  state = {
    loading: false,
    timeError: {},
    timeIsChanged: false,
    gettingPromosData: false,
    gtmErrorName: { name: '' },
    errorState: [],
    showToast: false,
    showError: true
  };

  handleTimeRangeError = ({ values }) => {
    // Here dates set function will be called
    console.log("Handle time range values", values);

    // console.log("Value is changing from", values);
    if (!isEqual(this.prevValues, values)) {
      const { listing, bookingConfig, updateBooking, transaction, localTimeZone } = this.props;
      const isInstantBooking = listingIsInstantBooking(listing);
      const timeZone = listing.attributes.availabilityPlan
        ? listing.attributes.availabilityPlan.timezone
        : localTimeZone;
      const { bookingStartTime, bookingEndTime, bookingEndDate, bookingStartDate } = values;
      const isBusinessTime = (listing && listing.attributes && listing.attributes.metadata && listing.attributes.metadata.isBusinessTime) || false;
      if (!bookingStartTime || !bookingEndTime) return;
      const currentTimeError = this.state.timeError;
      const momentStart = moment(timestampToDate(bookingStartTime), localTimeZone);
      const momentEnd = moment(timestampToDate(bookingEndTime), localTimeZone);

      const now = moment();
      const endDate = bookingEndDate.date;
      const startDate = bookingStartDate.date;
      const startHours = timestampToDate(bookingStartTime).getHours();
      const startMinutes = timestampToDate(bookingStartTime).getMinutes();
      const endHours = timestampToDate(bookingEndTime).getHours();
      const endMinutes = timestampToDate(bookingEndTime).getMinutes();
      const startDateWithProperHours = startDate.setHours(startHours, startMinutes, 0, 0);
      const endDateWithProperHours = endDate.setHours(endHours, endMinutes, 0, 0);
      const updateBookingStartDate = timestampToDate(startDateWithProperHours);
      const updateBookingEndDate = timestampToDate(endDateWithProperHours);
      const momentUpdateBookingStartDate = moment(
        timestampToDate(startDateWithProperHours),
        localTimeZone
      );
      const momentUpdateBookingEndDate = moment(
        timestampToDate(endDateWithProperHours),
        localTimeZone
      );

      const startTimeNotChanged = transaction && transaction.booking && moment(values.bookingStartDate.date).isSame(moment(transaction.booking.attributes.displayStart))

      if (momentUpdateBookingStartDate.isBefore(now) && !startTimeNotChanged) {
        currentTimeError['The start time cannot be in the past'] = true;
      } else {
        currentTimeError['The start time cannot be in the past'] = false;
      }

      const diffHoursUpdateBooking = momentUpdateBookingEndDate.diff(
        momentUpdateBookingStartDate,
        'hours',
        true
      );

      const listingHasHourlyBooking = get(listing, 'attributes.publicData.hourlyBooking', false);

      const diffHours = momentEnd.diff(momentStart, 'hours', true);

      const diffDays = sameDay(timestampToDate(bookingStartTime), timestampToDate(bookingEndTime))
        ?  Math.ceil(momentEnd.diff(momentStart, 'days', true))
        : Math.ceil(momentEnd.diff(momentStart, 'days', true));
      console.log("Diff days DDDDDD", diffDays);

      if (updateBooking) {
        const bookedStart =
          transaction && transaction.booking && transaction.booking.attributes.displayStart
            ? moment(transaction.booking.attributes.displayStart, localTimeZone)
            : null;
        const bookedEnd =
          transaction && transaction.booking && transaction.booking.attributes.displayEnd
            ? moment(transaction.booking.attributes.displayEnd, localTimeZone)
            : null;
        let diffBookedHours = bookedEnd.diff(bookedStart, 'hours', true);

        currentTimeError[`Updated time should be longer then booked or the same period of time`] =
          diffHoursUpdateBooking < diffBookedHours;
      }
      console.log("RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR", bookingConfig);
      if (!updateBooking) {
        this.props.setDifferenceInHours(diffHours);
        if (isInstantBooking && !this.props.isLongTermBooking) {
          const hoursToNow = Math.abs(now.diff(momentStart, 'hours', true));
          currentTimeError[
            `This car must be booked at least ${bookingConfig.paddingHours ||
              4} hours prior to the trip start time`
          ] =
            hoursToNow <
            (typeof bookingConfig.paddingHours === 'undefined'
              ? 4
              : parseInt(bookingConfig.paddingHours));

          const bookingStartHour =
            timeZone &&
            momentStart
              .clone()
              .tz(timeZone)
              .diff(
                momentStart
                  .clone()
                  .tz(timeZone)
                  .startOf('day'),
                'hours',
                true
              );


          currentTimeError[
            `Instant booking cars can not be picked up from 12am to 7am (car's owner timezone)`
          ] = bookingStartHour >= 0 && bookingStartHour <= 7;

          const bookingEndHour = momentEnd
            .clone()
            .tz(timeZone)
            .diff(
              momentEnd
                .clone()
                .tz(timeZone)
                .startOf('day'),
              'hours',
              true
            );

            if (isBusinessTime) {
              currentTimeError[
                `This listing can only be picked up or dropped off between 7.30AM & 5.30PM. Please update the timings and try again.`
                ] = (bookingStartHour >= 17.5 || bookingStartHour <= 7.5);

              currentTimeError[
                `This listing can only be picked up or dropped off between 7.30AM & 5.30PM. Please update the timings and try again.`
                ] = (bookingEndHour >= 17.5 || bookingEndHour <= 7.5);
            }

          currentTimeError[
            `Instant booking cars can not be dropped off from 12am to 7am (car's owner timezone)`
          ] = bookingEndHour >= 0 && bookingEndHour <= 7;
        } else {
          const hoursToNow = Math.abs(now.diff(momentStart, 'hours', true));
          currentTimeError[
            `This car must be booked at least ${bookingConfig.paddingHours ||
              2} hours prior to the trip start time`
          ] =
            hoursToNow <
            (typeof bookingConfig.paddingHours === 'undefined'
              ? 2
              : parseInt(bookingConfig.paddingHours));
        }
      }

      if (!updateBooking) {
        currentTimeError['Pickup time must be after the current time'] = momentStart.isSameOrBefore(
          now
        );
      }
      currentTimeError[
        `This listing can only be booked for more than ${listing.attributes.publicData.minimumDailyDuration} days. Please update your trip dates and try again. `
      ] =
        listing.attributes.publicData.minimumDailyDuration !== 1 &&
        parseInt(diffDays) > 1 &&
        parseInt(diffDays) < listing.attributes.publicData.minimumDailyDuration;

      if (!updateBooking) {
        if (listingHasHourlyBooking && (bookingConfig.type === bookingTypes.HOURLY)) {
          if (diffHours < 2) {
            currentTimeError[`The minimum trip duration has to be 2 hours.`] = diffHours < 2;
          }
          else {
            currentTimeError[`The minimum trip duration has to be 2 hours.`] = diffHours < 2;
          }
        }

        console.log("HEERRRRRRRRRRRRRRRRRRRRRRRRR", diffDays, bookingConfig, bookingTypes.HOURLY, bookingConfig.type === bookingTypes.HOURLY);
        if (bookingConfig.type === bookingTypes.HOURLY) {
          if (diffHours < bookingConfig.minimum)
            currentTimeError[
              `Invalid duration time (Equal/longer than ${bookingConfig.minimum} hours)`
            ] = true;
          else
            currentTimeError[
              `Invalid duration time (Equal/longer than ${bookingConfig.minimum} hours)`
            ] = false;
        } else {
          if (diffDays < bookingConfig.minimum) {
            currentTimeError[
              `Invalid duration time (Equal/longer than ${bookingConfig.minimum} days)`
            ] = true;
          } else {
            currentTimeError[
              `Invalid duration time (Equal/longer than ${bookingConfig.minimum} days)`
            ] = false;
          }
        }
      }

      if (updateBooking) {
        currentTimeError[`Booking start must be before booking end`] =
          startDateWithProperHours > endDateWithProperHours;
      } else {
        currentTimeError[`Booking start must be before booking end`] =
          bookingStartTime > bookingEndTime;
      }

      Object.keys(currentTimeError).forEach(key => {
        if (currentTimeError[key] === false) {
          delete currentTimeError[key];
        }
      });

      const hasTimeError = Object.keys(currentTimeError).length > 0 ;
      //

      this.prevValues = values;

      // console.log("Time error CCCCCCC", currentTimeError);

      this.setState(prev => ({
        ...prev,
        timeError: currentTimeError,
      }));



    }
  };

  beyond6Months = day => {
    const limit = moment().add(6, 'months');
    return !limit.isSameOrAfter(day);
  };

  getErrorEventFired = (values, error, errorType) => {
    const { listing } = this.props;
    const { gtmErrorName, errorState } = this.state;
    if (errorType === 'estimateError') {
      if (error) {
        if (error.status === 411) {
          gtmErrorName.name = 'You already have an accepted booking for the dates and times above.';
        } else if (
          (error.apiErrors &&
            error.apiErrors[0] &&
            error.apiErrors[0].code &&
            error.apiErrors[0].code === 'transaction-booking-time-not-available') ||
          error.status === 410
        ) {
          gtmErrorName.name =
            'Some other user have a reservation already made for some part of your trip dates. Please select other dates and try again.';
        } else if (error.status === 412) {
          gtmErrorName.name =
            'Some other user have a reservation already made for some part of your modified trip dates. Please select other dates and try again.';
        } else if (
          error.apiErrors[0] &&
          error.apiErrors[0].details === 'positive-or-zero-payout?'
        ) {
          gtmErrorName.name = 'Only bigger amount of hours alowed.';
        } else {
          gtmErrorName.name = 'Something went wrong. Please refresh the page.';
        }
      }
    }

    if (errorType === 'timeError') {
      gtmErrorName.name = Object.keys(this.state.timeError).join('. \n');
    }

    if (errorState && !errorState.includes(gtmErrorName.name) && gtmErrorName.name) {
      errorState.push(gtmErrorName.name);
      initiateEventFromListing({
        props: this.props,
        listing,
        pageName: 'ListingPage',
        event: EVENT_SEARCH_PRESSED_BOOK_GUEST_ERROR,
        bookingError: gtmErrorName.name,
        isHost: false,
      });

      this.setState({ errorState, gtmErrorName });
    }
  };

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      timeSlots,
      currentUser,
      initialDate,
      initialValues: rawInitialValues,
      isAllowedToBook,
      voucherCodeData,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        rawInitialValues={rawInitialValues}
        currentUser={currentUser}
        unitPrice={unitPrice}
        onSubmit={async values => {
          const { discountChoice, ...rest } = values;
          if(discountChoice === "credits" && !currentUser) {

            // console.log("discountChoice", discountChoice);
            this.setState({
              showToast: true,
              showError: false
            })
          }

          if (!discountChoice || discountChoice !== 'credits') {
            rest.signupCredits = 0;
          }
          rest.voucherCode = this.props.checkedCode;
          if (this.props.listing && this.props.listing.id.uuid && currentUser.id.uuid) {
            sendCheckoutSlackNotification({
              listingId: this.props.listing.id.uuid,
              userId: currentUser.id.uuid,
            });
          }
          this.props.onSubmit(rest);
        }}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            values,
            onFetchTimeSlots,
            timeZone,
            currentUser,
            listing,
            checkCodeInProgress,
            checkedCode,
            onCheckingVoucher,
            checkCodeErorr,
            onResetCode,
            onEstimateBreakdown,
            // tripFeesExperiment,
            estimateBreakdownInProgress,
            estimatedTx,
            requestButtonId,
            listingParams,
            onManageDisableScrolling,
            onReadInsurance,
            onOpenRentalAgreement,
            bookingConfig,
            estimateError,
            rawInitialValues,
            localTimeZone,
            isNewCar,
            isLongTerm,
            isLongTermBooking,
            monthlyTimeSlots,
            transaction,
            updateBooking,
            getChildTransactionData,
            onRequestToUpdateBooking,
            location,
          } = fieldRenderProps;

          if (estimateError || Object.keys(this.state.timeError).length > 0) {
            this.getErrorEventFired(values, estimateError, 'estimateError');
          }
          if (estimateError || Object.keys(this.state.timeError).length > 0) {
            this.getErrorEventFired(values, this.state.timeError, 'timeError');
          }
          if (!this.mounted) {
            form.batch(() => {
              Object.entries(rawInitialValues).forEach(entry => form.change(entry[0], entry[1]));
            });
            this.mounted = true;
          }

          const isInstantBooking = listingIsInstantBooking(listing);

          const { publicData = {} } = listing.attributes;
          const hourlyAvailability = publicData && publicData.hourlyAvailability;

          const requestToBookButtonMessage = isInstantBooking
            ? 'BookingDatesForm.requestToBookInstant'
            : 'BookingDatesForm.requestToBook';

          const {
            guestVerified: verifiedGuest,
            guestUnderVerify: underVerifiedGuest,
          } = currentUserIdentityStatus(currentUser);

          let { discountChoice } = values;
          const { signupCredits = 0 } = values;

          let savedVoucherCode = ""
          if (typeof window !== 'undefined') {
            if(window.localStorage && window.localStorage.getItem('codeType') && window.localStorage.getItem('codeType') === "referralCode") {
              savedVoucherCode = window.localStorage.getItem('code')
            }
          }
          if(savedVoucherCode && discountChoice !=="credits") {
            discountChoice = "promos"
            values.voucherCode = savedVoucherCode
          }

          const canRequestBooking = currentUserCanRequestToBooking(currentUser);

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;
          let diffHours = 0;
          let regularHoursDiff = 0;
          let peakHoursDiff = 0;
          let isBookingDatesEqual = false;
          let shouldEstimatePrice = true;
          let updateBookingStartDateWithProperHours = null;
          let updateBookingEndDateWithProperHours = null;

          // Calculate difference for trip modification

          if (updateBooking && values && values.bookingStartDate && values.bookingEndDate) {
            const updateBookingStartDate = values.bookingStartDate.date;
            const updateBookingEndDate = values.bookingEndDate.date;

            const updateBookingStartHours = timestampToDate(values.bookingStartTime).getHours();
            const updateBookingStartMinutes = timestampToDate(values.bookingStartTime).getMinutes();
            const updateBookingEndHours = timestampToDate(values.bookingEndTime).getHours();
            const updateBookingEndMinutes = timestampToDate(values.bookingEndTime).getMinutes();

            updateBookingStartDateWithProperHours = updateBookingStartDate.setHours(
              updateBookingStartHours,
              updateBookingStartMinutes,
              0,
              0
            );
            updateBookingEndDateWithProperHours = updateBookingEndDate.setHours(
              updateBookingEndHours,
              updateBookingEndMinutes,
              0,
              0
            );

            const momentUpdateBookingStartDate = moment(
              timestampToDate(updateBookingStartDateWithProperHours),
              localTimeZone
            );
            const momentUpdateBookingEndDate = moment(
              timestampToDate(updateBookingEndDateWithProperHours),
              localTimeZone
            );

            let updateBookingParentTransactionStartDate =
              transaction &&
              transaction.booking &&
              transaction.booking.attributes &&
              transaction.booking.attributes.displayStart;
            let updateBookingParentTransactionEndDate =
              transaction &&
              transaction.booking &&
              transaction.booking.attributes &&
              transaction.booking.attributes.displayEnd;
            const momentTripBookingStartDate = moment(updateBookingParentTransactionStartDate);
            const momentTripBookingEndDate = moment(updateBookingParentTransactionEndDate);

            const updateBookingDiffHours = momentUpdateBookingEndDate.diff(
              momentUpdateBookingStartDate,
              'hours',
              true
            );
            let updateBookingParentTransactionDiffHours = moment(
              updateBookingParentTransactionEndDate
            ).diff(moment(updateBookingParentTransactionStartDate), 'hours', true);

            diffHours = updateBookingDiffHours - updateBookingParentTransactionDiffHours;

            const tripBookingPeriod = new BookingPeriod(
              momentTripBookingStartDate,
              momentTripBookingEndDate
            );
            const updateBookingPeriod = new BookingPeriod(
              momentUpdateBookingStartDate,
              momentUpdateBookingEndDate
            );
            const listingPricing = get(listing, 'attributes.publicData.pricing', null);
            const tripBookingRegularHours = tripBookingPeriod.regularHoursCount(listingPricing);
            const tripBookingPeakHours = tripBookingPeriod.peakHoursCount(listingPricing);
            const updateBookingRegularHours = updateBookingPeriod.regularHoursCount(listingPricing);
            const updateBookingPeakHours = updateBookingPeriod.peakHoursCount(listingPricing);
            regularHoursDiff = updateBookingRegularHours - tripBookingRegularHours;
            peakHoursDiff = updateBookingPeakHours - tripBookingPeakHours;

            if (regularHoursDiff <= 0 && peakHoursDiff <= 0) {
              shouldEstimatePrice = false;
            }
            isBookingDatesEqual =
              momentTripBookingStartDate.isSame(momentUpdateBookingStartDate) &&
              momentTripBookingEndDate.isSame(momentUpdateBookingEndDate);
          }

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate &&
            endDate &&
            Object.entries(this.state.timeError).length === 0 &&
            !updateBooking
              ? {
                  unitType,
                  unitPrice,
                  startDate,
                  endDate,
                  timeZone,
                  listing,
                  credits: discountChoice === 'credits' ? signupCredits : 0,
                  discount: discountChoice === 'promos' && checkedCode ? checkedCode.discount : 0,
                  checkedCode: (discountChoice === 'promos' && checkedCode) || {},
                }
              : startDate &&
                endDate &&
                Object.entries(this.state.timeError).length === 0 &&
                updateBooking &&
                shouldEstimatePrice
              ? {
                  unitType,
                  unitPrice,
                  startDate: timestampToDate(updateBookingStartDateWithProperHours),
                  endDate: timestampToDate(updateBookingEndDateWithProperHours),
                  timeZone,
                  listing,
                  credits: discountChoice === 'credits' ? signupCredits : 0,
                  discount: discountChoice === 'promos' && checkedCode ? checkedCode.discount : 0,
                  isPaidAmount: true,
                  diffHours,
                  regularHoursDiff,
                  peakHoursDiff,
                  checkedCode: (discountChoice === 'promos' && checkedCode) || {},
                  provider: transaction.provider,
                  currentTx: transaction.id.uuid,
                }
              : null;

          const userCanRequestBooking = canRequestBooking && startDate && endDate;
          // console.log('data', estimatedTx);
          const userVerificationStatus = get(currentUser, 'attributes.profile.publicData.guestIdentityVerificationStatus', '');

          const isDeclinedUser = userVerificationStatus === 'declined';
          const notSubmitted = ['not submitted', 'not_submitted'].includes(userVerificationStatus.toLowerCase());
          const isPending = userVerificationStatus === 'pending';
          const bookingInfo =
            bookingData && currentUser && currentUser.id && !isDeclinedUser && !notSubmitted
              ? (() => {
                  if (!isEqual(this.bookingData, bookingData) && shouldEstimatePrice) {
                    if (!isOwnListing) {
                      onEstimateBreakdown({
                        processAlias: updateBooking
                          ? config.updateBookingChargingProcessAlias
                          : config.masterProcessAlias,
                        bookingStart: updateBooking ? bookingData.startDate : bookingData.startDate,
                        bookingEnd: updateBooking ? bookingData.endDate : bookingData.endDate,
                        listing,
                        currentUser,
                        credits: bookingData.credits,
                        discount: bookingData.discount,
                        isPaidAmount: bookingData.isPaidAmount,
                        diffHours,
                        regularHoursDiff,
                        peakHoursDiff,
                        checkedCode: (discountChoice === 'promos' && checkedCode) || {},
                        provider: bookingData.provider,
                        currentTx: bookingData.currentTx,
                        // tripFeesExperiment,
                      });
                    }
                    this.bookingData = bookingData;
                  }

                  return (
                    <div className={css.priceBreakdownContainer}>
                      <h3 className={css.priceBreakdownTitle}>
                        <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                      </h3>
                      <EstimatedBreakdownMaybe
                        unitType={unitType}
                        estimating={estimateBreakdownInProgress}
                        estimatedTx={estimatedTx}
                      />
                    </div>
                  );
                })()
              : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const hasTimeError = Object.keys(this.state.timeError).length > 0 || estimateError;

          //
          // console.log("HELLO WE ARE GOOD", this.state.timeError)
          if(estimateError) {

          }

          const isDeposit = isChargeDeposit(estimatedTx);
          return (
            <Form onSubmit={handleSubmit} currentUser={currentUser} className={classes}>
            {monthlyTimeSlots && timeZone ? (
                <Fragment>
                  <FormSpy currentUser={currentUser} subscription={{ values: true }}
                           onChange={this.handleTimeRangeError} />
                  <FormSpy
                    subscription={{ values: true }}
                    currentUser={currentUser}
                    onChange={props => {
                      const {values} = props;
                      const { discountChoice, signupCredits } = values;
                      if (this.prevDiscountChoice !== discountChoice) {
                        this.prevDiscountChoice = discountChoice;
                        onResetCode();
                        form.batch(() => {
                          form.change('voucherCode', null);
                          form.change('signupCredits', 0);
                        });
                      }
                      if (discountChoice === 'credits' && !signupCredits) {
                        form.batch(() => {
                          form.change(
                            'signupCredits',
                            this.props.currentUser && this.props.currentUser.id ? this.props.currentUser.attributes.credits : 0,
                          );
                        });
                      }
                    }}
                  />
                  <FieldStartDateAndTimeInput
                    {...dateInputProps}
                    className={css.bookingDates}
                    listingId={listingId}
                    bookingStartLabel={bookingStartLabel}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    timeSlots={timeSlots}
                    localTimeZone={localTimeZone}
                    isOutsideRange={isLongTerm ? undefined : this.beyond6Months}
                    location={location}
                    updateBooking={updateBooking}
                    initialValues={rawInitialValues}
                    transaction={transaction}
                  />
                  <FieldEndDateAndTimeInput
                    {...dateInputProps}
                    className={css.bookingEndDates}
                    listingId={listingId}
                    bookingStartLabel={bookingStartLabel}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    timeSlots={timeSlots}
                    localTimeZone={localTimeZone}
                    isOutsideRange={isLongTerm ? undefined : this.beyond6Months}
                    location={location}
                    hourlyAvailability={hourlyAvailability}
                    updateBooking={updateBooking}
                    initialValues={rawInitialValues}
                    transaction={transaction}
                  />
                  {(this.state.showToast) ? (
                    <AlertBox
                      title="Oops!"
                      message="Please login/signup to make a booking"
                      type="info"
                    />
                  ) : null}
                  {!isLongTermBooking && !updateBooking && (
                    <div className={css.fieldsPromos}>
                      <FieldSelect
                        labelClassName={css.label}
                        id='discountChoice'
                        name='discountChoice'
                        label={`Promotions & credits`}
                        className={css.checkDiscount}
                        onChange={()=> {
                          if(!currentUser) {
                            this.setState({
                              showToast: true,
                              showError: false
                            })
                          } else {
                            this.setState({
                              showError: true
                            })
                          }

                          setTimeout(() => {
                            this.setState({
                              showToast: false
                            })
                          }, 2000)
                        }}
                        disabled={
                          // isNewCar ||
                          estimateBreakdownInProgress ||
                          !startDate ||
                          !endDate ||
                          this.state.loading
                        }
                      >
                        <option value='none'>None...</option>
                        <option value='credits'>I want to use my available credits</option>
                        <option value='promos'>I have a promotional voucher</option>
                      </FieldSelect>
                      {discountChoice && discountChoice === 'credits' && signupCredits <= 0 && this.state.showError ?
                        <div className={css.error}>{'No Credit balance'}</div> : ''}
                      {discountChoice === 'promos' && (
                        <React.Fragment>
                          <div className={css.voucherContainer}>
                            <FieldTextInput
                              id="voucherCode"
                              name="voucherCode"
                              className={css.voucherInput}
                              type="text"
                              label="Input your voucher"
                              placeholder="Type your voucher here..."
                              disabled={this.state.loading}
                            />

                            <Button
                              className={css.voucherButton}
                              type='button'
                              inProgress={checkCodeInProgress}
                              disabled={
                                estimateBreakdownInProgress || !values.voucherCode
                              }
                              ready={!!checkedCode}
                              onClick={() => {
                                const { voucherCode, ...rest } = values;
                                const startDate = timestampToDate(rest.bookingStartTime);
                                const endDate = timestampToDate(rest.bookingEndTime);
                                if(currentUser) {
                                  onCheckingVoucher({
                                    code: voucherCode,
                                    data: {
                                      startDate,
                                      endDate,
                                    },
                                  });
                                } else {
                                  this.setState({
                                    showToast: true
                                  })
                                }
                              }}
                            >
                              Check
                            </Button>
                          </div>
                          {checkCodeErorr && (
                            <p className={classNames(css.smallPrintForMember, css.error)}>
                              Invalid voucher code
                            </p>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  )}
                  {bookingInfo}
                  {Object.keys(this.state.timeError).length ? (
                    <div className={css.error}>
                      {Object.keys(this.state.timeError).join('. \n')}
                    </div>
                  ) : null}

                  {isDeclinedUser && (
                    <>
                      <div className={`${css.error} ${css.errorHeading}`}>
                        Your verification status has been declined
                      </div>
                      <div className={css.error}>
                        Your identity verification has failed, and we cannot process your request. Contact support for any further clarification.
                      </div>
                    </>
                  )}
                {estimateError && estimateError.status === 411 ? (
                  <div className={css.error}>
                    You already have an accepted booking for the dates and times above.
                  </div>
                ) : estimateError &&
                  estimateError.apiErrors &&
                  estimateError.apiErrors[0] &&
                  estimateError.apiErrors[0].code &&
                  estimateError.apiErrors[0].code === 'transaction-booking-time-not-available' ? (
                  <div className={css.error}>
                    Some other user have a reservation already made for some part of your trip
                    dates. Please select other dates and try again.
                  </div>
                ) : estimateError && estimateError.status === 410 ? (
                  <div className={css.error}>
                    Some other user have a reservation already made for some part of your trip
                    dates. Please select other dates and try again.
                  </div>
                ) : estimateError && estimateError.status === 412 ? (
                  <div className={css.error}>
                    The modified dates (or part of them) have a booking made by another user.
                    Check in with our support team if you need any assistance.
                  </div>
                ) : estimateError && estimateError.status === 413 ? (
                  <div className={css.error}>
                   Since your profile is not verified yet, you need to book a trip at least 2 hours
                   prior to the trip start time.
                  </div>
                ) : estimateError && estimateError.status === 416 ? (
                  <div className={css.error}>
                    Your profile verification is in progress. Our team usually completes verification in under 2 hours. We'll notify you via email and text as soon as we're finished.
                  </div>
                ) : estimateError && estimateError.status === 417 ? (
                  <div className={css.error}>
                    You have not submitted a verification request. Please complete the verification process.
                  </div>
                ) : estimateError && estimateError.status === 418 ? (
                  <div className={css.error}>
                    Your identity verification has failed, and we cannot process your request. Contact support for any further clarification.
                  </div>
                ) : estimateError &&
                  estimateError.apiErrors[0] &&
                  estimateError.apiErrors[0].details === 'positive-or-zero-payout?' ? (
                  <div className={css.error}>Only bigger amount of hours allowed</div>
                ) : estimateError ? (
                  <div className={css.error}>Something went wrong. Please refresh the page</div>
                ) : null}
                  {updateBooking && <div className={css.submitSection}>
                    <div className={css.submitSectionInner}>
                      {transaction ? (
                        <div className={css.submitSectionPrice}>
                          <PrimaryButton
                            type='submit'
                            disabled={
                              hasTimeError || estimateBreakdownInProgress || !userCanRequestBooking || isDeclinedUser || notSubmitted
                            }
                            inProgress={estimateBreakdownInProgress}
                            id={requestButtonId}
                          >
                            <FormattedMessage id={requestToBookButtonMessage} />
                          </PrimaryButton>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  }
                  {!updateBooking && <div className={submitButtonClasses}>
                    {isDeposit && !isLongTermBooking ? null : null}
                    {startDate && endDate && estimatedTx ? (
                      <p className={css.smallPrintForMember}>
                        <FormattedMessage id='BookingPanel.memberUseDrivelah' />
                      </p>
                    ) : null}

                    {currentUser && currentUser.id ? (
                      <PrimaryButton
                        type='submit'
                        disabled={
                          hasTimeError || estimateBreakdownInProgress || !userCanRequestBooking || isDeclinedUser || notSubmitted
                        }
                        inProgress={estimateBreakdownInProgress}
                        id={requestButtonId}
                      >
                        <FormattedMessage id={requestToBookButtonMessage} />
                      </PrimaryButton>
                    ) : (
                      <NamedLink
                        name='LoginPage'
                        to={{
                          state: {
                            startDate,
                            endDate,
                            startTime,
                            endTime,
                            params: listingParams,
                            isFromListingPage: true,
                          },
                        }}
                      >
                        <PrimaryButton type='button'>
                          <FormattedMessage id={requestToBookButtonMessage} />
                        </PrimaryButton>
                      </NamedLink>
                    )}

                   {/* {currentUser &&
                    currentUser.id &&
                    userVerificationStatus == 'not_submitted' &&
                    !isOwnListing && (
                      <div className={css.notVerifiedGuestWarning}>
                        <p>
                          <FormattedMessage id="BookingDatesForm.notVerifiedGuestWarning"/>
                        </p>
                        <NamedLink name="UserVerification">
                          <FormattedMessage id="BookingDatesForm.notVerifiedGuestWarningLink"/>
                        </NamedLink>
                      </div>
                    )} */}
                     {/* {currentUser &&
                    currentUser.id &&
                    userVerificationStatus == 'pending' &&
                    !isOwnListing && (
                      <div className={css.notVerifiedGuestWarning}>
                        <p>
                          <FormattedMessage id="BookingDatesForm.underVerifiedGuestWarning"/>
                        </p>
                        <NamedLink name="UserVerification">
                          <FormattedMessage id="BookingDatesForm.underVerifiedGuestWarningLink"/>
                        </NamedLink>
                      </div>
                    )} */}

                    {!isInstantBooking && (
                      <p className={css.smallPrint}>
                        <FormattedMessage id='BookingPanel.noCharge' />
                      </p>
                    )}
                  </div>}
                </Fragment>
              ) : null}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              {!updateBooking && (
                <InsurancePanelNew
                  listing={listing}
                  showInsurance={true}
                  insuranceType={publicData.insurance}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onReadInsurance={onReadInsurance}
                />
              )}
              {!updateBooking && (
                <div className={css.rentalAgreementLink}>
                  <span onClick={onOpenRentalAgreement}>
                    <FormattedMessage id="BookingDatesForm.rentalAgreementLink" />
                  </span>
                </div>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  monthlyTimeSlots: null,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  isNewCar: false,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  // tripFeesExperiment: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
  isLongTerm: bool,
  isLongTermBooking: bool,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
